import { Feature } from "@/types/feature";

const featuresData: Feature[] = [
  {
    id: 1,
    icon: "/images/icon/icon-03.svg",
    title: "User-Friendly Experience",
    description:
      "Our AI-powered system seamlessly integrates into existing waste management processes, allowing users to maintain their daily routines while significantly improving recycling efficiency.",
  },
  {
    id: 2,
    icon: "/images/icon/icon-04.svg",
    title: "Advanced AI Analysis",
    description:
      "Utilizing cutting-edge computer vision and machine learning algorithms, our system accurately identifies and classifies waste materials in real-time, ensuring optimal recycling outcomes.",
  },
  {
    id: 3,
    icon: "/images/icon/icon-06.svg",
    title: "Sustainable Partnerships",
    description:
      "We collaborate with leading recycling facilities and environmental organizations to create a closed-loop system that maximizes resource recovery and minimizes environmental impact.",
  },
  {
    id: 4,
    icon: "/images/icon/icon-02.svg",
    title: "Data-Driven Insights",
    description:
      "Comprehensive analytics and reporting tools provide valuable insights into waste patterns and recycling performance, enabling continuous improvement and optimization.",
  },
  {
    id: 5,
    icon: "/images/icon/icon-01.svg",
    title: "Environmental Impact",
    description:
      "Our solution significantly reduces landfill waste and carbon emissions, contributing to a more sustainable future through improved recycling accuracy and efficiency.",
  },
  {
    id: 6,
    icon: "/images/icon/icon-05.svg",
    title: "Smart Infrastructure",
    description:
      "Seamlessly integrate our AI technology with existing waste management infrastructure to create an intelligent, automated recycling ecosystem.",
  },
];

export default featuresData;
