"use client";
import React from "react";
import SingleBrand from "./SingleBrand";
import brandData from "./brandData";

const Brands = () => {
  return (
    <>
      {/* <!-- ===== Clients Start ===== --> */}
      <section className="border border-x-0 border-y-stroke bg-alabaster py-11 dark:border-y-strokedark dark:bg-black">
        <div className="mx-auto max-w-c-1390 px-4 md:px-8 2xl:px-0">
          <style jsx>{`
            .brand-grid {
              display: grid;
              grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
              gap: 20px;
            }

            .brand-icon {
              width: 100px;
              height: 100px;
              object-fit: contain;
            }
          `}</style>
          <div className="brand-grid">
            {brandData.map((brand) => (
              <SingleBrand brand={brand} key={brand.id} className="brand-icon" />
            ))}
          </div>
        </div>
      </section>
      {/* <!-- ===== Clients End ===== --> */}
    </>
  );
};

export default Brands;
