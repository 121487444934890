import { Team } from "@/types/team";

const teamData: Team[] = [
  {
    id: 1,
    icon: "/images/icon/icon-03.svg",
    name: "LAW CHUN HEI, KEN",
    position: "Business Development",
    academicBackground: [
      "HKBU BBA-Economics & Data Analytics& FinTech",
      "HKCC-Associate in Business (Finance)",
    ],
    Experience: [
      "CTgoodjobs Future Leader Awards 2024 - Finalist",
      "Business Strategic Planning Assistant - HK01",
      "3. Senior Tutor (AI & Media literacy) - HK01 Edu",
      "4.The 2nd City I&T Grand Challenge- Pri&Sec Organizer",
    ],
    skills: ["Project Management"],
  },
  {
    id: 2,
    icon: "/images/icon/icon-03.svg",
    name: "Lin Chun Yin, RYAN",
    position: "Product Development",
    academicBackground: [
      "HKBU Bsc-Green Energy and Smart Technology",
      "UOW-Associate of Engineering",
    ],
    Experience: ["Waste Classification System Engineering project"],
    skills: [
      "Coding language: Java & C++ & Python",
      "IoT Building AND Machine learning",
    ],
  },
  {
    id: 3,
    icon: "https://github.com/WeGreen-AI/Intro/blob/main/Members/hikoAvator.png?raw=true",
    name: "Li YANPEI, HIKO",
    position: "Product Development",
    academicBackground: [
      "HKMU Bsc-Computer Engineering",
      "UOW-Associate of Engineering",
    ],
    Experience: [
      "Project EASILY-CV: CV Generator",
      "Project ASA: Cloud-based robot delivery management system",
      "Project RAKU: AI assistant job search system",
      "Project GAIA: Design data structure",
    ],
    skills: [
      "Mainly Typescript & Python",
      "Frontend + Backend Building",
      "Server Development",
    ],
  },
];

export default teamData;
