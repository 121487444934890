import { Brand } from "@/types/brand";

const brandData: Brand[] = [
  {
    id: 1,
    name: "Client",
    href: "https://www.hkbu.edu.hk/",
    image: "/images/brand/hkbu.png",
    imageLight: "/images/brand/hkbu.png",
  },
  {
    id: 2,
    name: "Client",
    href: "https://www.hkmu.edu.hk/",
    image: "/images/brand/hkmu.png",
    imageLight: "/images/brand/hkmu.png",
  },
];

export default brandData;
