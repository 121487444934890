"use client";
import Image from "next/image";
import { useState } from "react";

const Hero = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <section className="overflow-hidden pb-20 pt-35 md:pt-40 xl:pb-25 xl:pt-46">
        <div className="mx-auto max-w-c-1390 px-4 md:px-8 2xl:px-0">
          <div className="flex lg:items-center lg:gap-8 xl:gap-32.5">
            <div className=" md:w-1/2">
              <h4 className="mb-4.5 text-lg font-medium text-green-600 dark:text-green-400">
                Welcome to WeGreen AI
              </h4>
              <h1 className="mb-5 pr-16 text-4xl font-bold text-black dark:text-white xl:text-hero">
                Leading the Future of{" "}
                <span className="relative inline-block before:absolute before:bottom-2.5 before:left-0 before:-z-1 before:h-3 before:w-full before:bg-green-200 dark:before:bg-green-800">
                  Smart Recycling
                </span>
              </h1>
              <p className="text-lg text-gray-600 dark:text-gray-300 mb-8">
                Revolutionizing waste management through AI-powered solutions. 
                We're making recycling smarter, faster, and more efficient for a sustainable future.
              </p>

              <div className="mt-10 flex gap-5">
                <a
                  href="/support"
                  aria-label="Contact Us"
                  className="flex rounded-full bg-green-600 px-7.5 py-3.5 text-white duration-300 ease-in-out hover:bg-green-700 dark:hover:bg-green-500"
                >
                  Contact Us
                </a>
                <a
                  href="/docs"
                  aria-label="Learn More"
                  className="flex rounded-full border-2 border-gray-300 px-7.5 py-3.5 text-gray-700 duration-300 ease-in-out hover:border-green-600 hover:text-green-600 dark:text-gray-300 dark:hover:border-green-500 dark:hover:text-green-400"
                >
                  Learn More
                </a>
              </div>
            </div>

            <div className="animate_right hidden md:w-1/2 lg:block">
              <div className="relative 2xl:-mr-7.5">
                <Image
                  src="/images/shape/shape-01.png"
                  alt="shape"
                  width={46}
                  height={246}
                  className="absolute -left-11.5 top-0"
                />
                <Image
                  src="/images/shape/shape-02.svg"
                  alt="shape"
                  width={36.9}
                  height={36.7}
                  className="absolute bottom-0 right-0 z-10"
                />
                <Image
                  src="/images/shape/shape-03.svg"
                  alt="shape"
                  width={21.64}
                  height={21.66}
                  className="absolute -right-6.5 bottom-0 z-1"
                />
                <div className=" relative aspect-[700/444] w-full">
                  <Image
                    className="shadow-solid-l dark:hidden"
                    src="/images/hero/hero-light.svg"
                    alt="Hero"
                    fill
                  />
                  <Image
                    className="hidden shadow-solid-l dark:block"
                    src="/images/hero/hero-dark.svg"
                    alt="Hero"
                    fill
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
