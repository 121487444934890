import React from "react";

import Image from "next/image";
import { motion } from "framer-motion";
import { Team } from "@/types/team";

const SingleMember = ({ feature }: { feature: Team }) => {
  const { icon, name, position, academicBackground, Experience, skills } =
    feature;

  return (
    <>
      <motion.div
        variants={{
          hidden: {
            opacity: 0,
            y: -10,
          },

          visible: {
            opacity: 1,
            y: 0,
          },
        }}
        initial="hidden"
        whileInView="visible"
        transition={{ duration: 0.5 }}
        viewport={{ once: true }}
        className="animate_top z-40 rounded-lg border border-white bg-white p-7.5 shadow-solid-3 transition-all hover:shadow-solid-4 dark:border-strokedark dark:bg-blacksection dark:hover:bg-hoverdark xl:p-12.5"
      >
        <div className="relative flex h-55 w-55 items-center justify-center rounded-[4px] bg-primary">
          <Image src={icon} width={200} height={200} alt="title" />
        </div>
        <h3 className="mb-5 mt-7.5 text-xl font-semibold text-black dark:text-white xl:text-itemtitle">
          {name}
        </h3>
        <h3 className="text-l mb-5 mt-7.5 font-semibold text-black dark:text-white xl:text-itemtitle">
          {position}
        </h3>
        <h3 className="text-l mb-5 mt-7.5 font-semibold text-black dark:text-white xl:text-itemtitle">
          Academic
        </h3>

        {academicBackground?.map((item, index) => (
          <p key={item}>
            {index + 1}. {item}
          </p>
        ))}

        <h3 className="text-l mb-5 mt-7.5 font-semibold text-black dark:text-white xl:text-itemtitle">
          Experience
        </h3>
        {Experience?.map((item, index) => (
          <p key={item}>
            {index + 1}. {item}
          </p>
        ))}

        <h3 className="text-l mb-5 mt-7.5 font-semibold text-black dark:text-white xl:text-itemtitle">
          Skills
        </h3>
        {skills?.map((item, index) => (
          <p key={item}>
            {index + 1}. {item}
          </p>
        ))}
      </motion.div>
    </>
  );
};

export default SingleMember;
